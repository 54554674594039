@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.skills-wrapper {
    animation: 15s slide infinite linear;
    text-align: center;
    display: inline-block;
    animation-play-state: running;
    width: max-content;
}

.skills-section {
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
}

.skills-section:hover .skills-wrapper {
    animation-play-state: paused;
}