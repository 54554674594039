.project-card {
    width: 300px;
    height: 280px
}

/** firefox specific fix (not preserving 3d animation)**/
.project-card .front {
    transform: rotateY(0deg);
}

.card-inner {
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    position: relative;
}

.card-inner.is-flipped {
    transform: rotateY(180deg);
}

.card-face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
}

.back.card-face {
    transform: rotateY(180deg);
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.5;
}

.details-button {
    color: white;
    padding: 4px 6px;
    cursor: pointer;
}