.contact-form-wrapper {
    padding: 10px 35px;
    width: 100%;
}

.queries-wrapper {
    margin-bottom: 0.5rem;
    text-align: left;
}

@media (min-width: 768px) {
    .contact-form-wrapper {
        min-width: 50%;
        margin: 0 auto auto;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .contact-form-wrapper form {
        min-width: 400px;
        margin-right: 30px;
        margin-bottom: 0;
    }

    .contact-form-wrapper .contact-information-wrapper span {
        font-size: 25px;
        display: block;
    }
}