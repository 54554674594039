.hero-button {
    padding: 10px 20px;
    color: white;
    border-radius: 15px;
    width: 200px;
    font-size: 30px;
    margin-top: 20px;
}

.hero-image-wrapper {
    position: relative;
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 20px;
}

.hero-image-wrapper img {
    margin-top: -50px;
}

.hero-banner-wrapper {
    margin-bottom: auto;
}

.hero-text {
    color: var(--secondary);
    text-align: center;
}

.hero-text span {
    margin-bottom: 10px;
}

.hero-text .hero-name, .hero-text .hero-position, .hero-text .hero-intro {
    color: var(--primary);
}

.hero-text a {
    display: inline-block;
    padding: 10px 15px;
}

@media (min-width: 768px) {
    .hero-image-wrapper {
        position: relative;
        width: 700px;
        height: auto;
        border-radius: 0;
        margin: 0;
        padding: 0 30px;
    }

    .hero-image-wrapper img {
        margin-top: 0;
        border-radius: 10% 30%;
    }

    .hero-banner-wrapper {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 30px 20px;
    }

    .hero-text {
        max-width: 700px;
        text-align: left;
    }

    .hero-button {
        margin: 30px 0;
        width: 240px;
        font-size: 45px;
    }

    .hero-intro {
        font-size: 24px;
    }

    .hero-text a {
        margin-right: 20px;
    }

    .hero-button-wrapper {
        display: inline-block;
    }
}

@media (min-width:768px) and (max-width:1080px) {
    .hero-button {
        font-size: 28px;
        width: 160px;
    }
}