.email-result {
    margin-top: 20px;
}

.email-result-header {
    font-size: 48px;
    line-height: 1.15;
}

.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
   margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
 
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}

@media (min-width: 768px) {
    .email-wrapper h1 {
        font-size: 60px;
    }
}

.icon {
    width: 80px;
    height: 80px;
    border: 4px solid gray;
    border-radius: 50%;
    margin: 20px auto;
    padding: 0;
    position: relative;
    box-sizing: content-box;
    transform: scale(1);
}
.icon.error {
    border-color: #F27474
}
.icon.error .x-mark {
    position: relative;
    display: block
}
.icon.error .errorLine {
    position: absolute;
    height: 5px;
    width: 47px;
    background-color: #F27474;
    display: block;
    top: 37px;
    border-radius: 2px
}
.icon.error .errorLine.left {
    transform: rotate(45deg);
    left: 17px
}
.icon.error .errorLine.right {
    transform: rotate(-45deg);
    right: 16px
}
.icon.warning {
    border-color: #F8BB86
}
.icon.warning .body {
    position: absolute;
    width: 5px;
    height: 36px;
    left: 50%;
    top: 16px;
    border-radius: 2px;
    margin-left: -2px;
    background-color: #F8BB86
}
.icon.warning .dot {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-left: -3px;
    left: 50%;
    bottom: 16px;
    background-color: #F8BB86
}
.icon.info {
    border-color: #C9DAE1
}
.icon.info::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 29px;
    left: 50%;
    bottom: 17px;
    border-radius: 2px;
    margin-left: -2px;
    background-color: #C9DAE1
}
.icon.info::after {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-left: -3px;
    top: 19px;
    background-color: #C9DAE1;
    left: 50%;
}
.icon.success {
    border-color: #A5DC86
}
.icon.success::after,
.icon.success::before {
    content: '';
    position: absolute;
    width: 60px;
    height: 120px;
    background: #fff;
    transform: rotate(45deg)
}
.icon.success::before {
    border-radius: 120px 0 0 120px;
    top: -7px;
    left: -33px;
    transform: rotate(-45deg);
    transform-origin: 60px 60px
}
.icon.success::after {
    border-radius: 0 120px 120px 0;
    top: -11px;
    left: 30px;
    transform: rotate(-45deg);
    transform-origin: 0 60px
}
.icon.success .placeholder {
    width: 80px;
    height: 80px;
    border: 4px solid rgba(165, 220, 134, .2);
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2
}
.icon.success .fix {
    width: 5px;
    height: 90px;
    background-color: #fff;
    position: absolute;
    left: 28px;
    top: 8px;
    z-index: 1;
    transform: rotate(-45deg)
}
.icon.success .successLine {
    height: 5px;
    background-color: #A5DC86;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2
}
.icon.success .successLine.tip {
    width: 25px;
    left: 14px;
    top: 46px;
    transform: rotate(45deg)
}
.icon.success .successLine.long {
    width: 47px;
    right: 8px;
    top: 38px;
    transform: rotate(-45deg)
}
@keyframes animateSuccessTip {
    0%, 54% {
        width: 0;
        left: 1px;
        top: 19px
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px
    }
}

@keyframes animateSuccessLong {
    0%, 65% {
        width: 0;
        right: 46px;
        top: 54px
    }
    84% {
        width: 55px;
        right: 0;
        top: 35px
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px
    }
}
@keyframes rotatePlaceholder {
    0%, 5% {
        transform: rotate(-45deg);
    }
    100%,
    12% {
        transform: rotate(-405deg);
    }
}
.animateSuccessTip {
    animation: animateSuccessTip .75s
}
.animateSuccessLong {
    animation: animateSuccessLong .75s
}
.icon.success.animate::after {
    animation: rotatePlaceholder 4.25s ease-in
}
