@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

:root {
    --primary: #000;
    --secondary: rgb(52, 156, 235);
}

html {
    scroll-behavior:smooth
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

html, body, .main, #root {
    min-height: 100vh;
} 

body {
    font-family:  'Lato', sans-serif;
}

.button {
    padding: 15px;
    color: white;
    background-color: var(--secondary);
    border-radius: 6px;
    border: 2px solid var(--secondary);
    width: 200px;
    text-align: center;
    transition: all 0.3s ease-in;
    cursor: pointer;
}

.button:disabled {
    cursor: default;
    opacity: 0.7;
}

.button:hover:not([disabled]) {
    background-color: white;
    color: var(--primary);
}

.loading-wrapper {
    display: flex;
    position: fixed;
    /* width: 100%; */
    left: 0;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.7);
    z-index: 9999;   
}

textarea.field-error {
    border-color: red;
}

textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    font: inherit;
    padding: 10px;
    min-height: 200px;
    outline: none;
}

textarea:focus {
    border: 1px solid var(--secondary);
}